<template>
  <div
    class="grid grid-cols-2 gap-6 md:grid-cols-3 lg:grid-cols-4 lg:gap-12 xl:grid-cols-5"
  >
    <div
      v-for="collection in collections"
      :key="collection.id"
    >
      <nuxt-link
        :to="pathRoot + '/' + collection.handle"
        class="group mb-2 block overflow-hidden rounded border border-stone-400 drop-shadow"
      >
        <img
          src="https://cdn.shopify.com/s/files/1/0529/9378/6026/products/premium-luster-photo-paper-poster-_in_-12x18-front-635f5380eefa1_1500x.jpg?v=1667191684"
          class="block rounded transition-transform group-hover:scale-105"
        ></nuxt-link>

      <p class="text-center font-bold">
        <nuxt-link :to="pathRoot + '/' + collection.handle">{{
          collection.title
        }}</nuxt-link>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps(["collections", "pathRoot"])
const { collections, pathRoot } = toRefs(props)
</script>
